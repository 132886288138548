/* You can add global styles to this file, and also import other style files */
body { 
    background: #ECEFF1; 
    color: rgba(0,0,0,0.87); 
    font-family: Roboto, Helvetica, Arial, sans-serif; 
    margin: 0; 
    padding: 0; 
}

#message { 
    background: white; 
    max-width: 360px; 
    margin: 100px auto 16px; 
    padding: 32px 24px 16px; 
    border-radius: 3px; 
}

#image_app{
    max-width: 128px;
    max-height: 128px;
    display: block; 
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    text-align: center;
    align-items: center;
    width: 50%;
    box-shadow: 0 15px 15px rgba(0,0,0,0.12), 
    0 15px 15px rgba(0,0,0,0.24);
    border-radius: 8%;
}

/*
#message h3 { 
    padding-top: 15px;
    display: block; 
    text-align: center; 
    font-size: 14px; 
    font-weight: 300; 
    color: #1D334A; 
    margin: 0 0 16px;
}
*/

#message p { 
    line-height: 140%; 
    margin: 16px 0 24px; 
    font-size: 14px; 
}

#message a { 
    display: block; 
    text-align: center; 
    background: #15CCD2; 
    text-transform: uppercase; 
    text-decoration: none; 
    color: white; 
    padding: 16px; 
    margin-top: 20px;
    border-radius: 4px; 
}

#message, #message a { 
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 
    0 1px 2px rgba(0,0,0,0.24);
}

#load { 
    color: rgba(0,0,0,0.4); 
    text-align: center; 
    font-size: 13px; 
}

@media (max-width: 600px) {
    body, #message { margin-top: 0; background: white; box-shadow: none; }
    body { border-top: 16px solid #1D334A; }
}